.home {
    background-color: linear-gradient(90deg, #000 0%, #232233 50%, #000 100%);
    height: 100vh;
    padding-top: 50px;
}

.hero {
  position: relative;
  height: 25vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.heroBackground {
  position: absolute;
  top: 50px;
  left: 0;
  height: 25vh;
  width: 100%;
  background-image: url('../../Images/Backgrounds/dg-banner-2.png');
  background-size: auto 100%;
  background-repeat: repeat-x;

  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  @media (max-width: 768px) {
    background-size: auto 100%;
    background-repeat: repeat-x;
  }
}

.heroBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Images/Backgrounds/dg-banner-2.webp');
  background-size: auto 100%;
  background-repeat: repeat-x;
  filter: url(#blur);
  z-index: -1;
}

.heroContent {
  position: relative;
  z-index: 1;
  padding: 10px;
}

.home-title {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  padding-left: auto;
  padding-right: auto;

  text-shadow: 12px 12px 14px rgba(0, 0, 0, 0.8);

  font-size: 5rem;

  /* Mobile */
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.home-subtitle {
  color: #fff2eb;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  text-align: center;

  text-shadow: 12px 12px 14px rgba(0, 0, 0, 0.8);

  font-size: 3rem;

  /* Mobile */
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

  .home-body {
    font-size: 16px;
    padding: 5px;
    margin-bottom: 0;
    text-align: center;

    min-height: calc(100vh - 50px - 25vh - 50px);
    height: auto;

    background: linear-gradient(45deg, #000 0%, #292929 50%, #000 100%);
    color: #fff2eb;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  .home-body-Text {
    font-size: 1.5rem;
    padding: 5px;
    margin-bottom: 0;
    text-align: center;

    width: 50%;
    margin-left: auto;
    margin-right: auto;

    color: #fff2eb;


    @media (max-width: 768px) {
      font-size: 1rem;
      width: 95%;
      height: auto;
    }
  }

  #link {
    background: linear-gradient(-60deg, coral, lavender, pink, coral, lavender, pink);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 300% 300%;
    animation: gradient 3s linear infinite;
  }

  @keyframes gradient {
    0% { background-position: 100% 0; }
    100% { background-position: 0 0; }
  }

  .redirect-button {
    display: inline-block;
    padding: 10px 20px;
    color: #fff2eb;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;

    margin-top: 20px;
  }

  .redirect-button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }

  .footer {
    height: 50px;
    background-color: rgb(23, 23, 23);
    color: rgb(255, 244, 230);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
