/* UserDashboard Style Sheet */

.container{
    align-items: center;
    justify-content: center;
    background-color: #fff2eb;
    min-height: 100vh;
    padding-top: 50px;
    max-width: 100%;
}

.user-hero {
    position: relative;
    height: 20vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .user-heroBackground {
    position: absolute;
    left: 0;
    height: 20vh;
    width: 100%;
    filter: blur(2px);
    background-size: auto 100%;
    background-repeat: repeat-x;
    background-image: url("../../Images/Backgrounds/disc-brands-banner.webp");

  }

  @media (min-width: 768px) {
    .heroBackground {
      animation: scroll 30s linear infinite;
    }
  }

  .heroContent {
    position: relative;
    z-index: 1;
    max-width: 100%;
  }

.user-dash-title {
    color: #fff2eb;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10rem;
    text-shadow: 2px 2px 10px rgba( 23, 23, 23, 1);
    text-align: center;

    @media screen and (max-width: 768px) {
      font-size: 3rem;
    }
}

.body {
    font-size: 18px;
    color: rgb( 23, 23, 23 );
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: calc(100vh - 20vh - 50px);
    max-width: 100%;
}





/* ----------------------------------- */
/*            User Dash Nav            */
/* ----------------------------------- */

.userDashNav {
    width: 250px;
    position: absolute;
    left: 20px;
    display: flex;
    flex-direction: column;

    height: calc(100% - 40px);

    margin-bottom: 50px;

    /* If on mobile, space properly so it doesnt overlap the userNav */
    @media (max-width: 1000px) {
      width: 100px;
    }
}





/* ----------------------------------- */
/*         UserNav Profile Pic         */
/* ----------------------------------- */

.userOverview {
  position: relative;

  margin-left: auto;
  margin-right: auto;

  margin-top: 50px;
  margin-bottom: 20px;

  /* Make smaller if on mobile */
  @media (max-width: 1500px) {
    height: 100px;
    width: 100px;
  }



}

.userPhoto {
  border-radius: 50%;
  height: 200px;
  width: 200px;

  /* make smaller on mobile */
  @media (max-width: 1500px) {
    height: 100px;
    width: 100px;
  }
}

.userPhotoBorder {
  position: absolute;

  border-radius: 50%;
  height: 200px;
  width: 200px;

  border-style: solid;
  border-width: 10px;
  border-top-color: rgb( 23, 23, 23 );
  border-left-color: rgb( 23, 23, 23 );
  border-right-color: #32CD32;
  border-bottom-color: #32CD32;

  /* center absolutely in userOverview*/
  position: absolute;
  margin-left: auto;
  margin-right: auto;

  animation: rotate 15s linear infinite;

  /* Smaller Border for mobile */
  @media (max-width: 1500px) {
    border-width: 5px;
    height: 100px;
    width: 100px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/* ----------------------------------- */
/*       UserNav UserName / Title      */
/* ----------------------------------- */

.userDisplayName {
  /* align the elements inside this div in line */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: bold;
  color:rgb( 23, 23, 23 );

  /* Smaller on Mobile */
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.userTitle {
  font-size: 16px;
  color:rgb( 23, 23, 23 );

}





/* ----------------------------------- */
/*       UserNav UserName / Title      */
/* ----------------------------------- */

.userOptionsContainer {
  /* align the elements inside this div in line */
  display: flex;
  flex-direction: column;

  text-align: left;
  font-size: 24px;

  padding-top: 20px;
  width: 100%;


  /* Smaller on Mobile */
  @media (max-width: 768px) {
    font-size: 16px;
  }

}

.userOption {
  /* align the elements inside this div in line */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 100%;

  color:rgb( 23, 23, 23 );
}

.userOption:not(:last-child) {
  border-bottom: 1px dashed rgb( 23, 23, 23 );
}

.userOptionButton {
  background-color: #32CD32;
  padding: 5px;
  border-radius: 10px;
  border: none;
  color:rgb();
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  height: 50px;
  width: 100%;

  /* hover stuff */
  transition-duration: 0.5s;


  /* Smaller on Mobile */
  @media (max-width: 768px) {
    font-size: 16px;
    height: 35px;
  }
}

.userOptionButton:hover {
  background-color: #00BFFF;
  box-shadow: 0 0 2px 4px rgba( 23, 23, 23, 0.4);
}





/* ----------------------------------- */
/*         User Dashboard Body         */
/* ----------------------------------- */

.userDashBody {
    /* to the right of the DashNav div */
    width: 75%;
    min-width: 200px;

    /* center */
    margin-left: 400px;

    display: flex;
    flex-direction: row;

    height: calc(100% - 40px);

    /* If on mobile, space properly so it doesnt overlap the userNav */
    @media (max-width: 1000px) {
      margin-left: 40%;
      margin-right: 0px;
      margin-top: 20px;
      height: calc(100% - 60px);
      flex-direction: column;
      width: calc(100% - 175px);
    }
}

.userInfo {
  width: 50%;
  max-width: 500px;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
}

.userInfoTitle {
  font-size: 3rem;
  color:rgb( 23, 23, 23 );
  margin-top: 40px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

.userInfoLine {
  /* align the elements inside this div in line */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-left: 0px;
}

.userInfoIcon {
  width: 30px;
  height: 30px;

  /* fit background to div */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  /* put in line with the next div */
  margin-right: 10px;
  display: "flex";
  flex-direction: "row";

}

.userInfoText {
  font-size: 1.3rem;
  color:rgb( 23, 23, 23 );

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.emailIcon {
  color: #fff2eb;
  filter: drop-shadow(0 0 2px rgb( 23, 23, 23));
}

.joinedIcon {
  color: #32CD32;
  filter: drop-shadow(0 0 0.5px rgb( 23, 23, 23));
}

.awardIcon {
  color: #00BFFF;
  filter: drop-shadow(0 0 1px rgb( 23, 23, 23));
  /* small gold circle in the middle of the icon, done by center radial gradient, and transposed up a little */
  background-image: radial-gradient(circle at center, #FFD700 0%, #FFD700 40%, transparent 40%, transparent 100%);
  background-position: 0px -5px;
}





/* ----------------------------------- */
/*                Footer               */
/* ----------------------------------- */

.footer {
  width: 100%;
  /* fog */
  background: rgba( 23, 23, 23 );
  box-shadow: 0 0 2px 4px rgba( 23, 23, 23, 0.12);
}

.footerText {
  font-size: 16px;
  color: #fff2eb;
}

.heart {
  color: red;
  font-size: 20px;
}
