/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 20px;
  right: 25px;
  top: 15px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #d9d9d9;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgb(88, 216, 110);
  transition: 0.5s;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
  margin-right: 15px;
  margin-top: 25px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: absolute;
  height: 100%;
  margin-top: 40px;
  max-width:min-content;
}

/* General sidebar styles */
.bm-menu {
  background: #121212;
  padding-right: 40px;
  padding-left: 20px;
  font-size: 1.5em;
  margin-top: 0px;
  width: 250px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #171717;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding-top: 30px;
  max-height: 200px;
}

/* Individual item */
.bm-item {
  display: inline-block;

  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.menu-item-group-login {
  display: flex;
  position: absolute;
  padding-bottom: 20px;
  width: 80%;
  bottom: 40px;
}

.menu-login {
  color: rgb(88, 216, 110);
  background-color: #121212;
  text-decoration: none;
  border: none;
  font-size: 24px;
  padding-left: 10px;
}

.menu-settings {
  width: 30px;
  height: 30px;
  border: none;
  margin-left: 60px;
  vertical-align: middle;
  background-color: #121212;

}

.menu-settings-icon {
  width: 30px;
  height: 30px;
  fill: rgb(88, 216, 110);
  background-color: #121212;
}

.menu-login:hover, .menu-settings:hover {
  cursor: pointer;
  transition: color 2s;
  color: #ffffff;
}

.menu-settings-icon:hover {
  cursor: pointer;

  /* rotate the icon on hover */
  transform: rotate(720deg);
  transition: transform 3s;
}

/* Highlight active link */
.activeLink {
  color: rgb(88, 216, 110);
}
