.scorecard-container {
    padding-top: 50px;

    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* ----------------------------------- */
/*         User's Saved Rounds         */
/* ----------------------------------- */
.userSavedRounds {
    width: 70%;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 500px;
  }

  .userSavedRound {
    flex-direction: column;
    margin-top: 20px;
    padding-left: 0px;
    color:rgb( 23, 23, 23 );
    justify-content: space-evenly;
  }

  .userSavedRoundsTitle {
    font-size: 3rem;
    color:rgb( 23, 23, 23 );
    margin-top: 40px;
    font-weight: bold;
    justify-content: space-evenly;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
      width: 100%;
    }
  }

  .userSavedRoundsList {
    width: 100%;
    max-width: 900px;
    height: 100%;
    min-height: 250px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    overflow-x: auto;
    white-space: nowrap;

    /* fogbox */
    background-color: #fff2eb;
    box-shadow: 0 0 2px 4px rgba( 23, 23, 23, 0.12);
    border-radius: 10px;
    padding-bottom: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #32CD32 #fff2eb;

    /* on mobile, make scrollable horizontally */
    @media (max-width: 768px) {
      overflow-x: auto;
      white-space: nowrap;
    }
  }

  .userTitle {
    font-size: 1.5rem;
    color:rgb( 23, 23, 23 );
    margin-left: 10px;
    justify-content: space-evenly;
    border-bottom: 2px solid rgb( 23, 23, 23 );
  }

  .userSavedRound {
    /* align the elements inside this div in line */
    display: contents;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color:rgb( 23, 23, 23 );
    margin-bottom: 20px;
    padding-top: 20px;
    padding-left: 0px;
    font-size: 1.5rem;
  }

  .userSavedRoundName {
    font-size: 1.25rem;
    color:rgb( 23, 23, 23 );
    margin-left: 10px;
    justify-content: space-evenly;
  }

  .userSavedRoundDate {
    font-size: 1.25rem;
    color:rgb( 23, 23, 23 );
    margin-left: auto;
    margin-right: 10px;
    justify-content: space-evenly;
    text-align: center;
  }

  .userSavedRoundTime {
    font-size: 1.25rem;
    color:rgb( 23, 23, 23 );
    margin-left: 10px;
    justify-content: space-evenly;
  }

  .userSavedRoundScore {
    font-size: 1.25rem;
    color:rgb( 23, 23, 23 );
    margin-left: 10px;
    justify-content: space-evenly;
  }

  .userSavedRoundRating {
    font-size: 1.25rem;
    color:rgb( 23, 23, 23 );
    margin-left: 10px;
    justify-content: space-evenly;
  }

  .userSavedRoundDateSaved {
    font-size: 1.25rem;
    color:rgb( 23, 23, 23 );
    margin-left: 10px;
    justify-content: space-evenly;
  }
