.container {
    height: 96vh;
    padding-top: 50px;
}

.jumbotron {
    height: 25vh;
    width: 100vw;

    position: absolute;
    top: 50px;

    text-align: center;
}

.jumbotron-background {
    height: 25vh;
    width: 100%;
    background-image: url('../../Images/Backgrounds/two-paths.webp');
    background-repeat: none;
    background-size: fill;
    background-position: center;
    z-index: 0;

    /* make smaller on mobile */
    @media screen and (max-width: 768px) {
        background-size: cover;

    }
}

.title {
    color: #fff2eb;
    text-shadow: 2px 2px 4px rgb(23, 23, 23);
    font-size: 4rem;
    margin-top: 50px;
    padding-top: 30px;
    vertical-align: middle;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    /* Make smaller on mobile */
    @media screen and (max-width: 768px) {
        font-size: 2rem;
        margin-top: 0px;
    }

}

.sub-title {
    font-size: 1.5rem;
    text-align: center;
    color: #fff2eb;
    text-shadow: 2px 2px 4px rgb(23, 23, 23);
}

.body-sub-title {
    color: rgb(23, 23, 23);
    font-size: 1rem;

    /* Make smaller on mobile */
    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
    }
}

.split-columns {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;

    /* Make smaller on mobile */
    @media screen and (max-width: 768px) {
        flex-direction: column;
        /* center the stuff */
        align-items: center;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }
}

.col {
    width: 80%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    padding-right: auto;
    padding-left: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    /* Make smaller on mobile */
    @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
        padding-bottom: 50px;
    }
}

ul {
    list-style-position: outside;
    list-style-type: disc;
    align-items: center;
    text-align: start;
    padding-left: 10%;
    padding-right: 10%;
}

.button-container {
    height: 75px;
    width: 250px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.support-buttons{
    background-color: #052d49;
        border: '1px solid rgba(23, 23, 23, 0.6)';
        border-radius: 10px;
        color: white;
        cursor: 'pointer';

        /* shadow */
        box-shadow: 0px 0px 10px 0px rgba(23, 23, 23, 0.6);

        height: 100%;
        width: 100%;
        text-align: center;
        font-size: 1.25rem;
        font-weight: bold;

        /* center the text */
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;



        /* on hover, change color and stuff */
        &:hover {
            /*  set the background to the green ive been using */
            background-color: #32CD32;
            color: #fff2eb;
            border: '1px solid rgb(137, 207, 240)';
            scale: 1.1;
            transition: 1s;
            cursor: pointer;
        }

        transition: 1s;
}

.faIcons {
    font-size: 1.5rem;
    margin-right: 10px;
    margin-left: 15px;
    /* the patreon red circle around it */
    color: white;
    background-color: #f96854;
    padding: 10px;
    border-radius: 50%;


}

.rocker-pill {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 30px;
    overflow: hidden;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 100%;
    max-width: 500px;
    height: 50px;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;

    /* Make smaller on mobile */
    @media screen and (max-width: 768px) {
        width: 80%;
    }
  }

  .rocker-pill input[type="radio"] {
    display: none;
  }

  .rocker-pill label {
    flex: 1;
    padding: 10px;
    text-align: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    height: 100%;
  }

  .rocker-pill input[type="radio"]:checked + label {
    background-color: #4caf50;
    color: white;
  }

