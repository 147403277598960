.about {
    padding-top: 50px;
    background: linear-gradient(45deg, #000 0%, #292929 50%, #000 100%);
    color: #fff2eb;
    text-align: center;
    padding-left: auto;
    padding-right: auto;
    text-shadow: 12px 12px 14px rgba(0, 0, 0, 0.8);
    min-height: calc(100vh - 100px);
    height: auto;
}

.about-hero {
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}

.about-body {
    min-height: calc(100vh - 20vh - 100px);
    height: auto;
    padding: 20px;
}

ul {
    list-style-type: none;
    padding-bottom: 20px;
}

ul li {
    list-style-type: none; /* Remove default bullets */
    position: relative; /* Allow absolute positioning within each list item */
}

ul li::before {
    content: "•"; /* Create a bullet point with a pseudo-element */
    position: absolute;
    left: -1em; /* Position the bullet point to the left of the list item */
    animation: pulse 5s infinite; /* Apply the animation */
}

/* Apply different animation delays to each list item */
ul li:nth-child(1)::before { animation-delay: 0.4s; }
ul li:nth-child(2)::before { animation-delay: 0.8s; }
ul li:nth-child(3)::before { animation-delay: 1.2s; }
ul li:nth-child(4)::before { animation-delay: 1.6s; }
ul li:nth-child(5)::before { animation-delay: 2s; }
ul li:nth-child(6)::before { animation-delay: 2.4s; }
ul li:nth-child(7)::before { animation-delay: 2.8s; }


@keyframes pulse {
    0% { transform: scale(0); }
    50% { transform: scale(2); }
    100% { transform: scale(0); }
}
